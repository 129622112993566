/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React from "react"
import Avocat from "../../images/avocat.jpg"
import Btp from "../../images/btp.jpg"
import Etude from "../../images/etude.jpg"
import Expert from "../../images/expert.jpg"

  
  export default function Example() {
    return (
      <div className="bg-white">
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
            <div className="space-y-5 sm:space-y-4">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Une solution à portée de clic !</h2>
              <p className="text-xl text-gray-500">
              Chaque cas de fissuration est unique.<br />
              Décrivez-nous votre demande. Nous vous mettons alors en relation avec la ou les entreprises, pour un service adapté à votre situation.
              </p>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                    <a
                    href="/contact/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-amber-600 hover:bg-amber-800 md:py-4 md:text-lg md:px-10"
                    >
                    Envoyer votre demande
                    </a>
                </div>
                </div>
            </div>
            <div className="lg:col-span-2">
              <ul
                className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
              >
                
                  <li key="expert">
                    <a href="/expert-fissures/">
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src={Expert} alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Expert bâtiment indépendant</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                        Diagnostic et rapport d’expertise.
                        </p>
                        <ul className="text-gray-500">
                            <li>- Comprendre les causes de la fissuration </li>
                            <li>- Evaluer les risques</li>
                            <li>- Disposer de préconisations techniques. </li>
                        </ul>
                      </div>
                    </div>
                    </a>
                  </li>
                  <li key="etude">
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src={Etude} alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Bureau d’étude de sol</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                        Rapport d'étude géotechnique
                        </p>
                        <ul className="text-gray-500">
                            <li>- Diagnostic géotechnique G5</li>
                            <li>- Etude préalable à travaux de reprise</li>
                            <li>- Responsabilité décennale</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li key="btp">
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src={Btp} alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Entreprises de travaux</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                        Réparation des ouvrages fissurées
                        </p>
                        <ul className="text-gray-500">
                            <li>- Confortement du sol</li>
                            <li>- Consolidation des fondations</li>
                            <li>- Renforcement structurels</li>
                            <li>- Responsabilité décennale</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li key="avocat">
                    <div className="space-y-4">
                      <div className="aspect-w-3 aspect-h-2">
                        <img className="object-cover shadow-lg rounded-lg" src={Avocat} alt="" />
                      </div>
                      <div className="text-lg leading-6 font-medium space-y-1">
                        <h3>Avocats</h3>
                      </div>
                      <div className="text-lg">
                        <p className="text-gray-500">
                        Défense des intérêts des sinistrés
                        </p>
                        <ul className="text-gray-500">
                            <li>- Négociation avec l’expert d’assurance</li>
                            <li>- Recours contre un tiers</li>
                            <li>- Litige amiable ou judiciaire</li>
                            <li>- Conseils juridiques</li>
                        </ul>
                      </div>
                    </div>
                  </li>


              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
  