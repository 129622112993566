/* This example requires Tailwind CSS v2.0+ */
import React from "react"
export default function Section2() {
    return (
      <div className="bg-gray-100">
        <div className="my-16 max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-sky-600 tracking-wide uppercase">MAISON, APPARTEMENT, IMMEUBLE</p>
            <h2 className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-4xl">
                Entrez en relation avec les entreprises de votre choix
            </h2>
            <p className="mt-5 mx-auto text-xl text-gray-500">
            Découvrez vers qui vous tourner en cas de fissuration de votre maison ?
            </p>
            <p className="mt-5 mx-auto text-xl text-gray-500">
            Qui choisir entre un expert fissure, un bureau d’étude géotechnique, un architecte, un maître d'œuvre ou encore un maçon ?
            </p>
          </div>
        </div>
      </div>
    )
  }
  