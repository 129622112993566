/* This example requires Tailwind CSS v2.0+ */
import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export default function Section1() {
  return (
    <div className="max-w-7xl mx-auto relative mt-8 sm:mt-16 lg:mt-16">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="mx-4 lg:col-start-2">
              <h1 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-6xl lg:text-5xl xl:text-5xl">
                <span className="block xl:inline">Débarrassez-vous durablement des </span>{' '}
                <span className="block text-sky-600 xl:inline"> fissures</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Fissuration.fr, un service innovant et unique en France, pour vous aider à résoudre votre problématique de fissuration.
              </p>
              <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Causes des fissures, évaluation des risques, mesures conservatoires d’urgence, travaux de confortement des bâtiments, renforcement structurel et embellissement. 
              </p>
              <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
              Financement et garanties. Défense de vos intérêts, recours amiable et judiciaire.
              </p>
              <div className="mt-10 sm:flex sm:justify-center lg:justify-start">
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a
                    href="/contact/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-500 hover:bg-sky-700 md:py-4 md:text-lg md:px-10"
                  >
                    Intéressé(e), cliquez-ici
                  </a>
                </div>
              </div>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <div className="relative mx-auto">
                <StaticImage 
                  src="../../images/fissuration.jpg" 
                  alt="Fissuration"
                  width={490}
                  height={482}
                />
               </div>
          </div>
        </div>
    </div>




  )
}
