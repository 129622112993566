import React from "react";

import {
  DocumentTextIcon
} from '@heroicons/react/outline'

const features = [
  {
    name: 'Causes des fissures',
    description: 'Découvrez les différentes origines des fissures dans les maisons et immeubles',
    icon: DocumentTextIcon,
    lien: "/faq/cause-fissures/",
  },
  {
    name: 'Dangerosité des fissures',
    description: "Demander l'avis d'un spécialiste des fissures et évaluer les risques à court et moyen terme",
    icon: DocumentTextIcon,
    lien: "/faq/fissure-grave/",
  },
  {
    name: 'Fissures évolutives',
    description: 'Les fissures sur votre maison bougent. Il est probable que leurs causes soient encore actives',
    icon: DocumentTextIcon,
    lien: "/faq/fissure-evolutive/",
  },
  {
    name: 'Fissures traversantes',
    description: "Une fissure traversante est une fissure visible à la fois à l’extérieur de votre maison qu'à l’intérieur",
    icon: DocumentTextIcon,
    lien: "/faq/fissure-tranversante-maison/",
  },
  {
    name: 'Fissures en escalier',
    description: 'Ce type de fissure est souvent associé à la sécheresse et à un grave problème structurel',
    icon: DocumentTextIcon,
    lien: "/faq/fissure-escalier-secheresse/",
  },
  {
    name: 'Sol argileux et fissuration des maisons',
    description: 'Des millions de logements français sont exposés au retrait gonflement des argiles',
    icon: DocumentTextIcon,
    lien: "/faq/argile-fissures-maison/",
  },
]

export default function Example() {
  return (
    <div className="relative bg-white py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-md px-6 text-center sm:max-w-3xl lg:max-w-7xl lg:px-8">
        <h2 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          Questions fréquentes sur la fissuration des bâtiments
        </h2>
        <p className="mx-auto mt-5 max-w-prose text-xl text-gray-500">
          Vous avez des questions au sujet des fissures sur votre maison, appartement ou immeuble. Informez vous grâce à nos articles thématiques.
        </p>
        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-3">
            {features.map((feature) => (
            <a href={feature.lien}>
              <div key={feature.name} className="pt-6">
                <div className="flow-root rounded-lg bg-gray-50 px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center rounded-xl bg-sky-600 p-3 shadow-lg">
                        <feature.icon className="h-8 w-8 text-white" aria-hidden="true" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-semibold leading-8 tracking-tight text-gray-900">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base leading-7 text-gray-600">{feature.description}</p>
                  </div>
                </div>
              </div>
            </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}


