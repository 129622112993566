import React from "react"

import { Helmet } from "react-helmet"
import Banner from "../components/headers/banner"
import Header from "../components/headers/header"
import Footer from "../components/footers/footer"
import Section1 from "../components/index/Section1"
import Section2 from "../components/index/Section2"
import Section3 from "../components/index/Section3"
import Section4 from "../components/index/Section4"
import SectionFAQ from "../components/index/SectionFAQ"

export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Fissuration.fr</title>
        <meta name="description" content="Fissuration.fr, un service innovant et unique en France, pour vous aider à résoudre votre problématique de fissuration." />
        <link rel="canonical" href="https://www.fissuration.fr/" />
      </Helmet>
      <header>
        <Banner />
        <Header />
      </header>
      <main>
        <Section1 />
        <Section2 />
        <Section3 />
        <SectionFAQ />
        <Section4 />
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}