/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import React from "react"
import { GlobeAltIcon, LightningBoltIcon, MailIcon } from '@heroicons/react/outline'
import { CheckIcon, ThumbUpIcon, UserIcon } from '@heroicons/react/solid'

import Img1 from "../../images/mise-en-relation-fissuration.jpg"
import Img2 from "../../images/offre-fissuration.jpg"
import Img3 from "../../images/partie-fissuration.jpg"


  const tabs = [
    { id: 1, name: 'Mise en relation', href: '#link1', current: true },
    { id: 2, name: 'Offre personnalisée', href: '#link2', current: false },
    { id: 3, name: 'C’est parti !', href: '#link3', current: false },
  ]
  
  const relationFeatures = [
    {
      id: 1,
      name: 'Votre situation',
      description:
        'Expliquez-nous votre situation',
      icon: GlobeAltIcon,
    },
    {
      id: 2,
      name: 'Nos offres',
      description:
        'Recevez des offres de la part d’entreprise à même de répondre à votre problématique',
      icon: MailIcon,
    },
    {
      id: 3,
      name: 'Votre choix',
      description:
        'Restez libre de choisir la ou les entreprises qui vous correspondent',
      icon: LightningBoltIcon,
    },
  ]

  const timeline = [
    {
      id: 1,
      titre: "Envoyez votre demande",
      content: 'Chaque cas de fissuration est unique. Grâce à vos explications, nous pouvons vous guider vers le type d’entreprise qui vous correspond. L’envoi de votre demande prend seulement 5 minutes.',
      href: '/',
      icon: UserIcon,
      iconBackground: 'bg-sky-500',
    },
    {
      id: 2,
      titre: "Mise en relation",
      content: 'Fissuration.fr possède de nombreux professionnels partenaires partout en France. Votre demande est transmise aux entreprises. Après analyse, vous recevez alors une offre personnalisée. Grâce à nous, vous trouverez toujours le professionnel répondant à vos critères.',
      href: '/',
      icon: ThumbUpIcon,
      iconBackground: 'bg-sky-500',
    },
    {
      id: 3,
      titre: "Réalisation de la prestation",
      content: 'Vous pouvez poursuivre la relation avec l’entreprise de votre choix et démarrer la prestation. Engagez le professionnel après avoir reçu son devis et après avoir échangé par email et téléphone, seulement si vous êtes convaincu.',
      href: '/',
      icon: CheckIcon,
      iconBackground: 'bg-sky-500',
    },
  ]
  
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  function resetCurrent() {
    tabs.map((tab) => (
      tab.current = false
    ))
  }

  function setCurrent(tab) {
    tab.current = true
  }
  
  export default function Section3() {
    const [openTab, setOpenTab] = React.useState(1);
    return (
      <div className="max-w-7xl mx-auto ">
        <div className="hidden sm:block">
          <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
            {tabs.map((tab, tabIdx) => (
              <a
                onClick={e => {
                    e.preventDefault();
                    setOpenTab(tab.id);
                    resetCurrent();
                    setCurrent(tab);
                }}
                data-toggle="tab"
                role="tablist"
                key={tab.name}
                href={tab.href}
                className={classNames(
                  tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                  tabIdx === 0 ? 'rounded-l-lg' : '',
                  tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                  'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <span>{tab.name}</span>
                <span
                  aria-hidden="true"
                  className={classNames(
                    tab.current ? 'bg-sky-500' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5'
                  )}
                />
              </a>
            ))}
          </nav>
        </div>

        <div className="mt-4 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
        <div className="px-4 py-5 flex-auto">
            <div className="tab-content tab-space">
            <div className={openTab === 1 ? "block" : "sm:hidden"} id="link1">
              <div className="relative mt-4 sm:mt-16 lg:mt-8">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <p className="text-base font-semibold text-sky-600 tracking-wide uppercase">Mise en relation</p>
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Gérer votre problématique fissuration en toute sérénité</h2>
                    <p className="mt-3 text-lg text-gray-500">
                    Le site Fissuration.fr est le hub qui connecte les propriétaires ou gestionnaires de bâtiments sinistrés 
                    (maisons, appartements, immeubles en copropriété, locaux professionnels…) aux entreprises spécialisées dans la fissuration.
                    </p>
                    <dl className="mt-10 space-y-10">
                      {relationFeatures.map((item) => (
                        <div key={item.id} className="relative">
                          <dt>
                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-sky-500 text-white">
                              <item.icon className="h-6 w-6" aria-hidden="true" />
                            </div>
                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                          </dt>
                          <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      height={374}
                      src={Img1}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={openTab === 2 ? "block" : "sm:hidden"} id="link2">
            <div className="relative mt-4 sm:mt-16 lg:mt-8">
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2">
                    <p className="text-base font-semibold text-sky-600 tracking-wide uppercase">Offre personnalisée</p>
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Pourquoi choisir Fissuration.fr ?</h2>
                    <p className="mt-3 text-lg text-gray-500">
                    Fissuration.fr est un site édité par la société Koudepouce. Notre objectif est de vous simplifier la vie en vous permettant de trouver des solutions personnalisées et au meilleur prix.
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                    Sur Fissuration.fr vous trouverez des informations et conseils sur les désordres dans le bâtiment. Nos articles thématiques sont claires, pédagogiques et compréhensibles de tous.
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                    Grâce à nous, gagnez également du temps en recevant des devis sur mesure, répondant parfaitement à votre besoin.
                    </p>
                    <p className="mt-3 text-lg text-gray-500">
                    Notre service de mise en relation est gratuit et sans engagement. Vous avez le dernier mot et restez libre de choisir la ou les entreprises de votre choix. 
                    </p>
                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      height={374}
                      src={Img2}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={openTab === 3 ? "block" : "sm:hidden"} id="link3">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="lg:col-start-2 mt-8">
                    <p className="text-base font-semibold text-sky-600 tracking-wide uppercase">C’est parti !</p>
                    <h2 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Comment cela marche t-il ?</h2>
                    <p className="mt-3 text-lg text-gray-500">
                    Avocat en droit immobilier, expert fissure indépendant, expert d’assuré, bureau d’étude de sol, architecte, maître d'œuvre, société de micropieux, spécialiste de l’injection de résine expansive, entreprise générale de travaux, façadier…
                    </p>
                    
                    <div className="flow-root mt-8">
                      <ul className="-mb-8">
                        {timeline.map((event, eventIdx) => (
                          <li key={event.id}>
                            <div className="relative pb-8">
                              {eventIdx !== timeline.length - 1 ? (
                                <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                              ) : null}
                              <div className="relative flex space-x-3">
                                <div>
                                  <span
                                    className={classNames(
                                      event.iconBackground,
                                      'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white text-white'
                                    )}
                                  >
                                    {event.id}
                                  </span>
                                </div>
                                <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                  <div>
                                    <p className="text-sm text-gray-800">
                                      <strong>{event.titre}{' '}</strong>
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      {event.content}{' '}
                                    </p>
                                  </div>
                                  <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                    <time dateTime={event.datetime}>{event.date}</time>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>



                  </div>

                  <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                    <img
                      className="relative mx-auto"
                      width={490}
                      height={374}
                      src={Img3}
                      alt=""
                    />
                  </div>
                </div>
            </div>
            </div>
        </div>
        </div>
      </div>
    )
  }
  